.hero {
  height: 100vh;
  // font-family: 'SF Pro Light';
  // Center child (hero-content) vertically
  display: flex;
  align-items: center;
  position: relative;
  // Center child (hero-content) horizontally
  // justify-content: center;

  h1 {
    @include phone {
      font-size: 1.7em;
    }
    @include phablet {
      font-size: 1.8em;
    }
    @include tablet {
      font-size: 1.9em;
    }
    @include desktop{
      font-size: 2em;
    }
    @include widescreen{
      font-size: 2em;
    }
  }

  h2 {
    @include phone {
      font-size: 1em;
      line-height: 1.4;
    }
    @include phablet {
      font-size: 1.4em;
    }
    @include tablet {
      font-size: 1.5em;
    }
    @include desktop {
      font-size: 1.5em;
    }
    @include widescreen {
      font-size: 1.5em;
    }
  }
}

.hero-text {
  font: {
    weight: 300;
  }
  max-width: 900px;
  margin: 20px 0px 50px;
}

.name {
  font-weight: 600;
  color: $blue;
}

.icon-scroll {
  position: absolute;
  left: 50%;
  right: 50%;
  bottom: 90px;
  height: 18px;
  width: 18px;  
  @include phone {
    bottom: 0px !important;
  }
}

.icons-hero {
  padding: 0 32px 0 0;
}

.scroll-active {
  color: $syntax-bg-lighter;
}
