.card-nav {
    text-transform: uppercase;
    font-size: 1.1em;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  
    @include phone {
      grid-gap: 0 10px;
    }
  
    @include phablet {
      grid-gap: 0 30px;
    }
  
    @include tablet {
      grid-gap: 0 30px;    
    }
  
    @include desktop {
      grid-gap: 0 40px;
    }
    
    @include widescreen {
      grid-gap: 0 40px;
    }
  }
  
  .year {
    margin: 0px 0 20px;
    width: 100%;
    padding: 10px 0px;
    text-align: center;
    background-color: #2c2c2c;
    border-radius: 0.25em;
    cursor: pointer;
  }
  
  .card {
    padding: 20px;
    border-radius: 0.25em;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
    // min-height: 250px;
    display: flex;
    flex-direction: column;
  
    .img {
      padding: 0;
      height: auto;
      width: 100px;
      background-size: cover;
      background-position: center;
      filter: brightness(80%);
    }
  
    hr {
      border: 0;
      border-bottom: 1px solid $syntax-bg-lighter;
    }
  
    h1 {
      margin: 0px 0px 3px 0px;
      font-size: 1.5em;
      line-height: 1.4;
      flex-wrap: wrap;
      font-weight: 500;      
    }
  
    p {
      font-weight: 400;
      line-height: 1.5;
      margin: 10px 0px 20px 0px;
    }

    @include phone {
      h1 {
        font-size: 1.5em;
      }
      p {
        font-size: 0.95em;
      }
    }
    @include phablet {
      h1 {
        font-size: 1.5em;
      }
      p {
        font-size: 0.95em;
      }
    }
    @include tablet {
      h1 {
        font-size: 1.5em;
      }
      p {
        font-size: 0.95em;
      }
    }
    @include desktop {
      h1 {
        font-size: 1.5em;
      }
      p {
        font-size: 1em;
      }
    }
    @include widescreen {
      h1 {
        font-size: 1.5em;
      }
      p {
        font-size: 1em;
      }
    }
  
    .tags {
      list-style: none;
      display: flex;
      position: relative;
      flex-wrap: wrap;
      margin: 5px 0px;
      padding: 0px 0px 0px 2px;
  
      text-transform: uppercase;
      color: $syntax-bg-lighter;
  
      font: {
        size: 0.9em;
        family: $monospace;
      }
  
      li {
        margin-right: 10px;
      }
    }
  }

  @include phone {
    .prev, .next {
      padding: 8px;
    }
    .card-back {
      height: 2.9em;
    }
  }
  @include phablet {
    .prev, .next {
      padding: 8px;
    }
    .card-back {
      height: 2.9em;
    }
  }
  @include tablet {
    .prev, .next {
      padding: 10px;
    }
    .card-back {
      height: 3em;
    }
  }
  @include desktop {
    .prev, .next {
      padding: 14px;
    }
    .card-back {
      height: 3.2em;
    }
  }
  @include widescreen {
    .prev, .next {
      padding: 14px;
    }
    .card-back {
      height: 3.5em;
    }
  }
  
  .card-icon{  
    padding: 0px 20px 0px 0px;
    font-size: 1.5em;
  }
  
  .prev, .next {
    cursor: pointer;
    width: auto;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 3px;
    user-select: none;
    position: absolute;
    bottom: 0;
  }
  
  .prev {
    left: 30%;
  }
  
  .next {
    right: 30%;
  }
  
  .numbertext {
    color: #f2f2f2;
    padding: 10px 0;
    bottom: 0;
    position: absolute;
    margin: auto !important;
    text-align: center;
    left: 40%;
    right: 40%;
  
    @include phone {
      font-size: 1em !important;
    }
    @include phablet {
      font-size: 1em !important;
    }
    @include tablet {
      font-size: 1.25em !important;
    }
    @include desktop {
      font-size: 1.4em !important;
    }
    @include widescreen {
      font-size: 1.5em !important;
    }
  
  }
  
  .card-back {
    background: $syntax-bg;
    position: absolute;
    opacity: 0.8;
    left: 30%;
    right: 30%;
    width: auto;
    bottom: 0;
    border-radius: .4em .4em 0 0;
  }
  
  .card-image{
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    border-radius: .25em;
  }
  
  .fade {
    -webkit-animation-name: fade;
    -webkit-animation-duration: 1.5s;
    animation-name: fade;
    animation-duration: 1.5s;
  }
  
  
  @-webkit-keyframes fade {
    from {opacity: .4}
    to {opacity: 1}
  }
  
  @keyframes fade {
    from {opacity: .4}
    to {opacity: 1}
  }