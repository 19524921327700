@font-face {
  font-family: 'SF Pro Light';
  src: local('SF Pro Light'),
    url('/fonts/SFProText-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'SF Pro';
  src: local('SF Pro'), url('/fonts/SFProText-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'SF Mono';
  src: local('SF Mono'), url('/fonts/SFMono-Regular.ttf') format('truetype');
}

@import './styles/main';
@import './styles/hero';
@import './styles/section';
@import './styles/role';
@import './styles/projects';
@import './styles/card';
@import './styles/footer';
