.section {
  @mixin tablet-desktop {
    display: grid;
    grid-template-columns: 1fr 6fr;
  }

  @include tablet {
    @include tablet-desktop;
    grid-gap: 70px;
  }
  @include desktop {
    @include tablet-desktop;
    grid-gap: 100px;
  }
  @include widescreen {
    @include tablet-desktop;
    grid-gap: 100px;
  }

  padding: 70px 0px;
  
}

// .section:after{
//   content: "test";
//   width: 60px;
//   height: 4px;
//   border-bottom: 1px solid rgba(255, 255, 255, 0.125);//add to section:after and make it partial border
// }

.section-title {
  text-transform: uppercase;
  color: $syntax-accent;
  font: {
    size: 1.2em;
    weight: 500;
  }

  @mixin phone-phablet {
    padding: 25px 0px;
  }

  @mixin tablet-desktop {
    text-align: right;
  }

  @include phone {
    @include phone-phablet;
  }

  @include phablet {
    @include phone-phablet;
  }

  @include tablet {
    @include tablet-desktop;
  }

  @include desktop {
    @include tablet-desktop;
  }

  @include widescreen {
    @include tablet-desktop;
  }
}

.section-content {
  font-weight: 400;
  font-size: 1em;
  line-height: 1.5;
  width: 100%;

  p {
    margin-top: 0;
    margin-bottom: 25px;
  }
}


.section-project {
  list-style: none;
  margin: 5px 0;
  text-transform: uppercase;
  color: $syntax-bg-lighter;
  font-size: .9em;
  font-family: $monospace;

  @mixin phone-phablet {
    padding: 25px 0px;
  }

  @mixin tablet-desktop {
    text-align: right;
  }

  @include phone {
    @include phone-phablet;
  }

  @include phablet {
    @include phone-phablet;
  }

  @include tablet {
    @include tablet-desktop;
  }

  @include desktop {
    @include tablet-desktop;
  }

  @include widescreen {
    @include tablet-desktop;
  }
}