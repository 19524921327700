.footer {
  padding: 20px;
  color: $syntax-bg-lighter;

  & p {
    margin: 2px;
  }
  
}


